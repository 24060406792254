import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserFacade } from './state/auth/auth.facade';
import { User } from './state/auth/auth.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  user$: Observable<User> = this.userService.user$;
  constructor(private userService: UserFacade, private router: Router) {
  }

  ngOnInit() {}

  logout() {
    this.userService.logout();
  }
}
