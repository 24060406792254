import { Action } from '@ngrx/store';
import { User } from './auth.model';

export const GET_USER = '[Auth] Get user';
export const AUTHENTICATED = '[Auth] Authenticated';
export const NOT_AUTHENTICATED = '[Auth] Not Authenticated';

export const GOOGLE_LOGIN = '[Auth] Google login attempt';
export const EMAIL_LOGIN = '[Auth] Email login attempt';
export const EMAIL_SIGNUP = '[Auth] Email Signup attempt';
export const LOGOUT = '[Auth] Logout';

export const LOGIN_SUCCESS = '[Auth] Login Success';
export const LOGOUT_SUCCESS = '[Auth] Logout Sucess';
export const AUTH_ERROR = '[Auth] Error';

export const SET_USER = '[Auth] Set User';
/// Get User AuthState

export class GetUser implements Action {
  readonly type = GET_USER;
  constructor(public payload?: any) {}
}

export class SetUser implements Action {
    readonly type = SET_USER;
    constructor(public payload?: any){}
}

export class Authenticated implements Action {
  readonly type = AUTHENTICATED;
  constructor(public payload?: any) {}
}

export class NotAuthenticated implements Action {
  readonly type = NOT_AUTHENTICATED;
  constructor(public payload?: any) {}
}

export class AuthError implements Action {
  readonly type = AUTH_ERROR;
  constructor(public payload?: any) {}
}

/// Google Login Actions

export class GoogleLogin implements Action {
  readonly type = GOOGLE_LOGIN;
  constructor(public payload?: any) {}
}

/// Email Login Actions

export class EmailLogin implements Action {
  readonly type = EMAIL_LOGIN;
  constructor(public payload?: any) {}
}

/// Email Signuo Actions

export class EmailSignup implements Action {
  readonly type = EMAIL_SIGNUP;
  constructor(public payload?: any) {}
}
/// Logout Actions

export class Logout implements Action {
  readonly type = LOGOUT;
  constructor(public payload?: any) {}
}

export class LoginSuccess implements Action {
  readonly type = LOGIN_SUCCESS;
}

export class LogoutSucess implements Action {
  readonly type = LOGOUT_SUCCESS;
}

export type All =
  | SetUser
  | GetUser
  | Authenticated
  | NotAuthenticated
  | GoogleLogin
  | EmailLogin
  | EmailSignup
  | AuthError
  | Logout
  | LoginSuccess
  | LogoutSucess;
