import { createFeatureSelector } from '@ngrx/store';
import { AppState } from '../state';
import * as userActions from './auth.actions';
import { User } from './auth.model';

export type Action = userActions.All;

const defaultUser = new User( '', 'GUEST', '');

/**
 * Define all store queries for Post(s)
 */
export namespace UsersQuery {
  export const getUser = (state: AppState) => state.user;
}
export const getUserSelector = createFeatureSelector<User>('user');

/// Reducer function
export function userReducer(state: User = defaultUser, action: Action) {
  switch (action.type) {

    case userActions.GET_USER:
      return { ...state };
    
    case userActions.SET_USER:
      return { ...state };
    
    case userActions.AUTHENTICATED:
        return { ...state, ...action.payload};

    case userActions.NOT_AUTHENTICATED:
        return { ...state, ...defaultUser };

    case userActions.AUTH_ERROR:
      return { ...state, ...action.payload};

    case userActions.LOGOUT:
      return { ...state };
    
    case userActions.LOGIN_SUCCESS:
      return { ...state };
    
    case userActions.LOGOUT_SUCCESS:
      return { ...state };
    
    default:
      return state;

  }
}
