<nav class="navbar navbar-expand-lg navbar-dark bg-dark" style="margin-bottom: 0 !important;">
    <div class="nav_bar_wrapper">
        <div class="navbar_group nav_group_left">
            <div class="brand_img">
                <a class="logo_link">
                    <img src="assets/img/logo.png" alt="Law Pavillion - Case Manager">
                </a>
            </div>
        </div>
        <button class="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
    </div>

    <div *ngIf="user$ | async as user" class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul *ngIf="user.uid" class="navbar-nav mx-auto">
            <li class="nav-item menuList-item text-white">
                <div>
                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                        class="menu__link_item text-white" [routerLink]="['/user/profile']"
                        style="cursor: pointer; font-size: 13px; text-decoration: none;">Profile</a>
                </div>
            </li>
            <li class="nav-item menuList-item text-white">
                <div>
                    <a (click)="logout()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                        class="menu__link_item text-white"
                        style="cursor: pointer; font-size: 13px; text-decoration: none;">Logout</a>
                </div>
            </li>
        </ul>
    </div>

</nav>