import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType, createEffect } from '@ngrx/effects';

import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';

import { Observable, of } from 'rxjs';
import { defer } from 'rxjs';
import '../../utils/rxjs.operators';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { AppState } from '../state';
import {  User } from './auth.model';
import { UsersQuery } from './auth.reducer';

import * as userActions from './auth.actions';
import { Router } from '@angular/router';
import { AngularFirestore} from '@angular/fire/firestore';
type Action = userActions.All;

@Injectable()
export class UserFacade {
  email!: any;
  password!: any;
  // ************************************************
  // Observable Queries available for consumption by views
  // ************************************************

  user$ = this.store.select(UsersQuery.getUser);
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private afAuth: AngularFireAuth,
    private db: AngularFireDatabase,
    public afs: AngularFirestore, // Inject Firestore service
    private router: Router
  ) {}

  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.GET_USER),
      map((action: userActions.GetUser) => action.payload),
      switchMap((payload) =>
        this.afAuth.authState.pipe(
          map((user: any) => {
            if (user) {
              /// User logged in
              const _user = new User(user.uid, user.displayName, user.email);
              this.router.navigate(['user']);
              return new userActions.Authenticated(_user);
            } else {
              /// User not logged in
              return new userActions.NotAuthenticated();
            }
          }),
          catchError((error) => of(new userActions.AuthError()))
        )
      )
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.LOGOUT),
      map((action: userActions.Logout) => action.payload),
      switchMap((payload: any) => {
        return of(this.afAuth.signOut());
      }),
      map((authData: any) => {
        return (
          new userActions.NotAuthenticated(), new userActions.LogoutSucess()
        );
      }),
      catchError((err: { message: any }) =>
        of(new userActions.AuthError({ error: err.message }))
      )
    )
  );

  init$ = createEffect(
    () =>
      defer(() => {
        this.store.dispatch(new userActions.GetUser());
      }),
    {
      dispatch: false,
    }
  );

  @Effect({ dispatch: false })
  logoutSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(userActions.LOGOUT_SUCCESS),
    tap(() => this.router.navigate(['/']))
  );


  /**
   *
   */
  logout(): Observable<User> {
    this.store.dispatch(new userActions.Logout());
    return this.user$;
  }

  
}
