export interface IUser {
    uid?:         string;
    displayName?: string;
    email?: string;
    error?: string;
    created?: number;
    recordings?: number;
}

export class User {
    constructor(public uid: string, public displayName: string, public email : string) {}
}
